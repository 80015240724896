import React from "react";
import CodeOrg from "assets/images/code_org.svg";
import BBC from "assets/images/new-homepage-assets/desktop/bbc_logo.svg";

const Card3 = ({ borderCss }) => {
  return (
    <div
      className={`right bg-white pl-[26px] sm:pl-10 p-10 sm:w-[409px] mr-2 relative sm:mt-0 ml-1 mt-8 ${
        borderCss ? borderCss : ""
      }`}
      style="box-shadow: 0px 6px 10px #00000029; border-radius: 22px;"
    >
      <p className="font-bold text-[18px] font-opensans">
        "Efficient and very good customer <br /> service."
      </p>
      <p className="text-[#00173A] font-opensans leading-relaxed pt-5">
        The Tomedes team was very responsive and quick to help us address any
        issues related to timeline and quality of translation...I would
        recommend the Tomedes team to any computer science curriculum providers.
      </p>

      <div className="pt-[4rem]">
        <img src={CodeOrg} alt="" />
        <p className="text-[18px] font-bold font-opensans pt-3">Jorge Castro</p>
      </div>
    </div>
  );
};

export default Card3;
