import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import FontUrl1 from "../assets/fonts/riotsquad.regular.ttf"

const SEO = ({ title, description, keywords, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: title || data.site.siteMetadata.title,
    description: description || data.site.siteMetadata.description,
    logo: {
      "@type": "ImageObject",
      url: "https://tomedes.gumlet.io/frontend/images/tomedes-logo.jpg",
    },
  };

  // for homepage only
  const ldJsonPrimary = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Tomedes Ltd",
    description:
      "Professional translation services. Join over 95,000 businesses who enjoy 24/7 support, 1 year guarantee and competitive prices. Get your quote now!",
    image: "https://tomedes.gumlet.io/frontend/images/tomedes-logo.svg",
    url: "https://www.tomedes.com/",
    sameAs: [
      "https://www.facebook.com/Tomedes.translation",
      "https://twitter.com/Tomedes",
      "https://www.linkedin.com/company/tomedes.com",
      "https://www.youtube.com/channel/UC9k2By4XhHEjI6usn0nJfNw",
    ],
    logo: {
      "@type": "ImageObject",
      url: "https://tomedes.gumlet.io/frontend/images/tomedes-logo.jpg",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "9450 SW Gemini Dr #34540,",
      addressLocality: "Beaverton",
      addressRegion: "OR",
      postalCode: "97008-7105",
      addressCountry: "United States",
    },
    email: "support@tomedes.com",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1 985 239 0142",
      contactType: "Customer Service",
    },
  };

  return (
    <Helmet htmlAttributes={{ lang: `en-US` }}>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
      <title>{title || data.site.siteMetadata.title}</title>
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />
      <script type="application/ld+json">
        {slug === "/" ? JSON.stringify(ldJsonPrimary) : JSON.stringify(ldJson)}
      </script>
      <link
        hreflang="en-us"
        rel="canonical"
        href={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <meta name="keywords" content={keywords || ""} />
      <meta property="twitter:card" data-page-subject="true" value="summary" />
      <meta property="twitter:site" data-page-subject="true" value="@Tomedes" />
      <meta
        property="og:site_name"
        data-page-subject="true"
        content="Tomedes"
      />
      <meta property="og:locale" data-page-subject="true" content="en_US" />
      <meta
        property="og:title"
        data-page-subject="true"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        property="og:keyword"
        data-page-subject="true"
        content={keywords || ""}
      />
      <meta property="robots" content="noindex" />
      <meta
        property="og:description"
        data-page-subject="true"
        content={description || data.site.siteMetadata.description}
      />
      <meta property="og:type" data-page-subject="true" content="article" />

    </Helmet>
  );
};

export default SEO;
