import React from "react";
import CarOne from "./GrowthCarouselSlides/carOne";
import CarTwo from "./GrowthCarouselSlides/carTwo";
import CarThree from "./GrowthCarouselSlides/carThree";
import CarOneNew from "./GrowthCarouselSlides/carOneNew";
import CarTwoNew from "./GrowthCarouselSlides/carTwoNew";
import CarThreeNew from "./GrowthCarouselSlides/carThreeNew";

const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 5000;

const NewGrowthCarousel = ({NewCards, headTitle}) => {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <h2 className="text-[35px] sm:text-[40px] font-bold font-secondary text-[#00173A] flex justify-center mx-auto text-center px-8 sm:px-0">
        {headTitle ? headTitle : "Our X Services can help you with: 3 Top Benefits"}
      </h2>
      {NewCards ? (
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          <div className="slide">
            <CarOneNew />
          </div>
          <div className="slide">
            <CarTwoNew />
          </div>
          <div className="slide">
            <CarThreeNew />
          </div>
        </div>
      ) : (
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          <div className="slide">
            <CarOne />
          </div>
          <div className="slide">
            <CarTwo />
          </div>
          <div className="slide">
            <CarThree />
          </div>
        </div>
      )}

      <div className="slideshowDots sm:mt-0 mt-[5rem]">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default NewGrowthCarousel;
