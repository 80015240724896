import * as React from "react"

const IconTruck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.855}
    height={23.884}
    {...props}
  >
    <path
      data-name="Icon awesome-shipping-fast"
      d="M29.108 16.42h-.746v-5.043A2.239 2.239 0 0 0 27.7 9.8l-4.66-4.66a2.239 2.239 0 0 0-1.581-.658h-2.053V2.239A2.24 2.24 0 0 0 17.167 0H5.225a2.24 2.24 0 0 0-2.24 2.239v2.239H.373A.374.374 0 0 0 0 4.851V5.6a.374.374 0 0 0 .373.373h12.688a.374.374 0 0 1 .373.373v.746a.374.374 0 0 1-.373.373H1.866a.374.374 0 0 0-.373.373v.746a.374.374 0 0 0 .373.373h9.7a.374.374 0 0 1 .373.373v.746a.374.374 0 0 1-.373.373H.373a.374.374 0 0 0-.373.373v.746a.374.374 0 0 0 .373.373h9.7a.374.374 0 0 1 .373.373v.746a.374.374 0 0 1-.373.373H2.985v5.971a4.478 4.478 0 1 0 8.956 0h5.971a4.478 4.478 0 1 0 8.956 0h2.239a.749.749 0 0 0 .746-.746v-1.491a.749.749 0 0 0-.745-.747ZM7.464 21.645A2.239 2.239 0 1 1 9.7 19.406a2.24 2.24 0 0 1-2.236 2.239Zm14.927 0a2.239 2.239 0 1 1 2.239-2.239 2.24 2.24 0 0 1-2.239 2.239Zm3.732-9.7h-6.717V6.717h2.057l4.66 4.66Z"
      fill="#ff7b16"
    />
  </svg>
)

export default IconTruck