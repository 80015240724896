import React from "react";
import Checked from "assets/CheckedIcon.svg";

const LegalBottomCardTwo = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={Checked} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Online 24/7 <br /> Customer Support
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            Our dedicated team works around <br /> the clock to ensure that{" "}
            <br /> we can answer your message <br /> within minutes.
          </p>
        </div>
      </div>
    </div>
  );
};
export default LegalBottomCardTwo;
