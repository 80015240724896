// import React from "react";
import Card from "./card";
import Card3 from "./carouselThree";
import Card2 from "./carouselTwo";
// import "./Style.css"

// const colors = ["#0088FE", "#00C49F", "#FFBB28"];
// // const colors = ["#0088FE", ];
// const delay = 5000;

// function AdsCarousel() {
//   const [index, setIndex] = React.useState(0);
//   const timeoutRef = React.useRef(null);

//   function resetTimeout() {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//   }

//     React.useEffect(() => {
//       resetTimeout();
//       timeoutRef.current = setTimeout(
//         () =>
//           setIndex((prevIndex) =>
//             prevIndex === colors.length - 1 ? 0 : prevIndex + 1
//           ),
//         delay
//       );

//       return () => {
//         resetTimeout();
//       };
//     }, [index]);

//   return (
//     <div className="slideshow">
//       <div
//         className="slideshowSlider mb-[18px] h-[481px] flex sm:gap-x-8"
//         // style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
//         style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
//       >

//         <div className="slide h-[481px]">
//           <Card/>
//         </div>
//         <div className="slide">
//           <Card2/>
//         </div>
//         <div className="slide">
//           <Card3/>
//         </div>
//       </div>

//       <div className="slideshowDots sm:mt-0 mt-8">
//         {colors.map((_, idx) => (
//           <div
//             key={idx}
//             className={`slideshowDot${index === idx ? " active" : ""}`}
//             onClick={() => {
//               setIndex(idx);
//             }}
//           ></div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default AdsCarousel;

import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function App() {
  const pagination = {
    clickable: true,
    bulletClass: `swiper-pagination-bullet`,
    renderBullet: (index, className) => {
      return `<span class="${className} feature-pagination"></span>`;
    },
  };

  useEffect(() => {
    const stylesheet = document.styleSheets[0];
    stylesheet.insertRule(
      ".swiper-pagination-bullet-active { background: orange !important;} ",
      0
    );
  }, []);
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={pagination}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <Card />
        </SwiperSlide>
        <SwiperSlide>
          <Card2 />
        </SwiperSlide>
        <SwiperSlide>
          <Card3 />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
