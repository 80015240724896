import React from "react";
import Checked from "assets/CheckedIcon.svg";

const CarOneNew = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={Checked} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            One-Year <br /> Guarantee
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            All our translation projects come <br /> with the promise of support
            in <br /> case of quality issues for up to <br /> one year.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarOneNew;
