import React from "react";
import BBC from "assets/bbc.png";
import Adidas from "assets/adidas.png";

const OurClients = () => {
  return (
    <div className="w-11/12 sm:w-8/12 mx-auto py-20">
      <h2 className="text-[22px] text-[#00173A] font-secondary text-center font-semibold">
        Trusted by Satisfied Clients Worldwide
      </h2>
      <div className="pt-12 grid grid-cols-2 gap-y-8 items-center justify-items-center sm:grid-cols-7 gap-x-10">
        <img
          src="https://tomedes.gumlet.io/assets/clients/amazon.svg?width=0"
          alt=""
        />
        <img
          src="https://tomedes.gumlet.io/assets/clients/google.svg?width=0"
          alt=""
        />
        <img
          src="https://tomedes.gumlet.io/assets/clients/microsoft.svg?width=0"
          alt=""
        />
        <img src={BBC} alt="" />
        <img
          src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg?width=0"
          alt=""
        />
        <img src={Adidas} alt="" />
        <img
          src="https://tomedes.gumlet.io/assets/clients/code.svg?width=0"
          alt=""
        />
      </div>
    </div>
  );
};

export default OurClients;
