import React, { useState } from "react";
import { DocumentData } from "./data";
import arrowDownSvg from "assets/icons/iconDownWhite.svg";
import ContactusMobile from "components/reusableComponents/contactusMobile/ContactusMobile";

const Industries = ({
  open,
  setOpen,
  head,
  content,
  DocumentData,
  contentTwo,
  contentThree,
}) => {
  const [openContactusMobileThree, setOpenContactusMobileThree] =
    useState(false);

  return (
    <div
      className={`${openContactusMobileThree && "pb-[38rem]"} bg-blue py-16`}
    >
      <div className="mx-auto">
        <div className="text-left sm:text-center px-3 sm:px-0">
          <h2 className="font-secondary sm:pl-0 pl-[16px] font-bold text-40 text-white mb-10">
            {head ? head : "Translation Services For Any Industry"}
          </h2>
          <p className="font-opensans text-lg sm:text-center text-left text-white mb-12 m-auto px-5 lg:px-0 lg:max-w-4xl xl:max-w-6xl">
            {content
              ? content
              : "Not just any translator will do here at Tomedes. We ensure that your translation project is handled by the right professionals with the appropriate background, from a diverse range of industries and sectors. Here are a few examples:"}
          </p>
          <p className="font-opensans text-lg sm:text-center text-left text-white mb-12 m-auto px-5 lg:px-0 lg:max-w-4xl xl:max-w-6xl">
            {contentThree ? contentThree : ""}
          </p>
          {/* desktop-view */}
          <div className="hidden sm:block">
            <div
              className={`grid sm:grid-cols-3 gap-2 gap-y-20 ${
                contentThree ? "mb-12" : "mb-4"
              }  m-auto w-fit max-w-4xl justify-center items-center `}
            >
              {DocumentData.map((item, i) => {
                return (
                  <div
                    className="mx-auto relative flex flex-col justify-center items-center"
                    key={i}
                  >
                    <img
                      className="m-auto py-6 w-[65px]"
                      src={item.image}
                      alt=""
                    />
                    <h3 className="font-opensans text-lg text-white absolute top-[100px] w-56">
                      {item.text}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
          {/* mobile view */}
          <div className="pl-4 sm:hidden">
            {DocumentData.map((item, i) => {
              return (
                <div className="flex items-center gap-x-4" key={i}>
                  <img className="py-6 w-[65px]" src={item.image} alt="" />
                  <span className="font-opensans text-lg text-white">
                    {item.text}
                  </span>
                </div>
              );
            })}
          </div>
          <button
            onClick={() => setOpen(true)}
            type="submit"
            className="mt-14 bg-orange hover:bg-[#FF8F3A] font-opensans text-14 font-semibold text-white w-full sm:w-[213px] h-[59px] cursor-pointer rounded-full hidden sm:inline-block"
          >
            CONTACT US
          </button>
          <button
            onClick={() => setOpenContactusMobileThree(true)}
            type="submit"
            className="mt-14 bg-orange hover:bg-[#FF8F3A] font-opensans text-14 font-semibold text-white w-full sm:w-[213px] h-[59px] cursor-pointer rounded-full sm:hidden flex justify-center items-center gap-x-4"
          >
            CONTACT US
            <img
              src={arrowDownSvg}
              alt="arrow down"
              className={`${
                openContactusMobileThree ? "visible" : "invisible absolute"
              } `}
            />
          </button>
          <div className="absolute left-0">
            <ContactusMobile
              open={openContactusMobileThree}
              setOpen={setOpenContactusMobileThree}
              noPlaceholder
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industries;
