import React, { useState } from "react";
import { Link } from "gatsby";
import QuotePopUp from "../../../components/popup/quotePopUp/index";
import ContactusMobile from "components/reusableComponents/contactusMobile/ContactusMobile";
import arrowDownSvg from "assets/icons/iconDownWhite.svg";

const AdContact = ({
  head,
  para,
  btnName,
  handleTop,
  paddingY,
  open,
  setOpen,
  mobileContactButton,
}) => {
  const [openContactusMobileOne, setOpenContactusMobileOne] = useState(false);

  return (
    <div
      className={`w-full ${
        openContactusMobileOne && "pb-28"
      }  bg-GlobalContact bg-cover bg-no-repeat mx-auto ${
        paddingY ? paddingY : "py-7"
      } px-8 md:px-12 text-white`}
    >
      <div className=" flex flex-col lg:flex-row md:items-center justify-between">
        <div className="text-[35px] sm:text-[40px] w-full lg:w-[60%]">
          <h2 className="font-medium sm:font-bold font-secondary sm:text-left text-center xl:text-center">
            {head ? head : "Contact Us 24/7"}
          </h2>
        </div>
        <div
          className={`${
            openContactusMobileOne && "pb-[30rem]"
          } my-8 md:my-0 w-full pt-4 lg:pt-0 lg:w-[35%] flex justify-center xl:justify-start mx-auto`}
        >
          {handleTop ? (
            <Link
              onClickCapture={handleTop}
              className={`font-opensans font-bold text-[18px] tsm:ext-[20px] text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer ${
                openContactusMobileOne ? "hidden sm:block" : null
              }   py-6 px-8 w-[300px] hover:bg-[#012A68]`}
            >
              {btnName ? btnName : "CONTACT US NOW"}
            </Link>
          ) : (
            <Link
              onClickCapture={() => setOpen(true)}
              className="font-opensans font-bold text-[18px] tsm:ext-[20px] text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer hidden sm:block  py-6 px-8 w-[300px] hover:bg-[#012A68]"
            >
              {btnName ? btnName : "CONTACT US"}
            </Link>
          )}
          {mobileContactButton ? (
            <>
              <a
                onClick={() => setOpenContactusMobileOne(true)}
                className={` ${
                  openContactusMobileOne ? "flex gap-x-4 justify-center" : null
                } font-opensans font-bold text-[18px] tsm:ext-[20px] text-center uppercase rounded-full bg-[#000C27] text-white cursor-pointer sm:hidden  py-6 px-8 w-[300px] hover:bg-[#012A68] relative`}
              >
                Contact Us
                <img
                  src={arrowDownSvg}
                  alt="arrow down"
                  className={`${
                    openContactusMobileOne ? "visible" : "invisible absolute"
                  } `}
                />
              </a>
              <div className="absolute py-20">
                <ContactusMobile
                  open={openContactusMobileOne}
                  setOpen={setOpenContactusMobileOne}
                  noPlaceholder
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <QuotePopUp open={open} setOpen={setOpen} />
    </div>
  );
};

export default AdContact;
