import React from "react";
import Card from "./card";
import Card3 from "./carouselThree";
import Card2 from "./carouselTwo";
import CarFour from "./Growth Carousel/carFour";
import CarOne from "./Growth Carousel/carOne";
import CarThree from "./Growth Carousel/carThree";
import CarTwo from "./Growth Carousel/carTwo";

const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FFBB28"];
const delay = 5000;

function GrowthCarousel() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <h2 className="text-[26px] sm:text-[40px] font-secondary text-[#00173A] flex justify-center mx-auto text-center">
        What Makes Tomedes Your Growth Partner?
      </h2>
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        <div className="slide">
          <CarOne />
        </div>
        <div className="slide">
          <CarTwo />
        </div>
        <div className="slide">
          <CarThree />
        </div>
        <div className="slide">
          <CarFour />
        </div>
      </div>

      <div className="slideshowDots sm:mt-0 mt-[5rem]">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default GrowthCarousel;
