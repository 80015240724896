import React, { useState } from "react";
import PopUpImage from "assets/images/contact_popup.webp";
import { useLocationGetInTouch } from "hooks/Forms/useLocationGetInTouch";
import { useForm } from "react-hook-form";
import ThankYouPopup from "components/popup/thankYouPopup";
import Loader from "components/loader";
import { useLocation } from "@reach/router";
import formCommonPostData from "utils/form-common-post-data";

export default function Modal({ open, setOpen }) {
  const [thankOpen, setThankOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useLocationGetInTouch();
  const location = useLocation();

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      message: data.message,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((_) => {
      reset();
      setThankOpen(true);
      setOpen(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && (
        <React.Fragment>
          <div
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div
              class="fixed inset-0 transition-opacity"
              style={{ "background-color": "black", opacity: "0.5" }}
            ></div>
            <div class="fixed z-10 inset-0 overflow-y-auto">
              <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none font-primary">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="relative  flex items-center justify-between  w-11/12 sm:w-auto  bg-white flex-col lg:flex-row max-w-5xl top-10 sm:top-0 sm:mt-16 py-5 lg:py-0"
                  >
                    <img
                      src={PopUpImage}
                      alt="loading..."
                      className="w-18 hidden lg:block"
                    />
                    <div className="px-4 relative right-0">
                      <button
                        className="closetbn block ml-auto font-bold w-[24px] outline-none focus:outline-none  ease-linear transition-all duration-150 "
                        type="button"
                        onClick={handleClose}
                      >
                        <img src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/close_dark.svg" />
                      </button>
                      <h5 className="text-orange font-arial  font-bold text-3xl text-center pb-5">
                        Contact <span className="text-blue">Us</span>
                      </h5>
                      <p
                        className="font-opensans text-sm text-center px-4 lg:px-10 pb-6 "
                        style={{ color: "#000C27" }}
                      >
                        Enter your details and we will get back to you within a
                        few minutes
                      </p>
                      <div className="flex justify-between items-center  flex-wrap lg:flex-nowrap lg:flex-row flex-col lg:gap-3 relative">
                        <label className="flex flex-col w-full">
                          <span
                            className="modal-label font-opensans text-[11px] "
                            style={{ color: "#000C27" }}
                          >
                            Name<i className="text-red">*</i>
                          </span>
                          <input
                            type="text"
                            name="name"
                            required
                            className="modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-2 py-1 w-full border border-pop-up-100 h-[32px] outline-none"
                            placeholder="Your name"
                            {...register("name", { required: true })}
                          />
                          {/* {errors.name && (
                            <span className="text-red text-xs absolute bottom-[-14px]">
                              This field is required
                            </span>
                          )} */}
                        </label>
                        <label className="flex flex-col w-full">
                          <span
                            className="modal-label font-opensans text-[11px] "
                            style={{ color: "#000C27" }}
                          >
                            Email Address<i className="text-red">*</i>
                          </span>
                          <input
                            type="email"
                            required
                            name="email"
                            className="modal-input font-opensans text-[11px] text-pop-up-200 min-w-[230px] px-2 py-1 w-full border border-pop-up-100 h-[32px] outline-none"
                            placeholder="Your email address"
                            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                          />
                          {/* {errors.email && (
                            <span className="text-red text-xs absolute bottom-[-14px]">
                              This field is required
                            </span>
                          )} */}
                        </label>
                      </div>
                      <label className="flex flex-col w-full">
                        <span
                          className="modal-label font-opensans text-[11px] mt-1"
                          style={{ color: "#000C27" }}
                        >
                          Tell us more about your needs
                        </span>
                        <textarea
                          id=""
                          name="message"
                          rows="2"
                          style="resize:none;"
                          className="modal-input px-2 py-2 font-opensans text-[11px] text-pop-up-200 border border-pop-up-100 w-full "
                          placeholder="One member of our team will contact you within a few minutes"
                          {...register("message")}
                        />
                        {errors.message && (
                          <span className="text-red text-xs">
                            This field is required
                          </span>
                        )}
                      </label>
                      <button
                        className="btn orn-btn w-full lg:w-32 h-10 text-white bg-orange font-opensans font-bold block mx-auto mt-2"
                        id="emailsubmit"
                      >
                        {status === "loading" ? <Loader /> : "SUBMIT"}
                      </button>
                    </div>
                    <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                  </form>
                </div>
                <div className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"></div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </>
  );
}
