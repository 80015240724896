import React from "react";
import second from "assets/wifiGroup.svg";

const CarTwo = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={second} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Wide Range of Expertise
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            Our translators work in diverse <br /> languages and industry
            sectors. You will <br /> be matched with the right language <br />{" "}
            professional to meet your translation <br /> project’s needs.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarTwo;
