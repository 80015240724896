import React from "react";
import Checked from "assets/CheckedIcon.svg";

const LegalTopCardOne = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={Checked} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Proven
            <br />
            High-Quality Results
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            We are tried and tested by major <br /> global heavyweights like
            <br /> Google and Amazon. Your translation <br /> project will be
            treated with just <br /> as much care to ensure <br /> the quality
            of work.
          </p>
        </div>
      </div>
    </div>
  );
};
export default LegalTopCardOne;
