import * as React from "react"

const CallCenter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28.632}
    height={28.632}
    {...props}
  >
    <path
      data-name="Path 784"
      d="M4.433 9.883H.839a.839.839 0 0 0-.839.839v8.985a.839.839 0 0 0 .839.839h3.594a.839.839 0 0 0 .839-.839v-8.985a.839.839 0 0 0-.839-.839Z"
      fill="#ff7b16"
    />
    <path
      data-name="Path 785"
      d="M20.156 19.587a.839.839 0 0 0 .839-.839v-1.857h.06a.839.839 0 0 0 0-1.678h-.06v-4.552a.839.839 0 0 0-1.432-.593l-3.861 3.859a3.036 3.036 0 0 0-.878 2.125.839.839 0 0 0 .839.839h3.654v1.857a.839.839 0 0 0 .839.839Zm-3.357-4.373a1.318 1.318 0 0 1 .091-.1l2.427-2.426v2.527Z"
      fill="#ff7b16"
    />
    <path
      data-name="Path 786"
      d="M13.354 18.749a.839.839 0 0 0-.839-.839H9.158a1.314 1.314 0 0 1 .091-.1l3.859-3.857a.839.839 0 0 0 .246-.593v-.449a3.085 3.085 0 0 0-6.17 0 .839.839 0 0 0 1.678 0 1.407 1.407 0 0 1 2.815 0v.1l-3.615 3.613a3.036 3.036 0 0 0-.878 2.125.839.839 0 0 0 .839.839h4.492a.839.839 0 0 0 .839-.839Z"
      fill="#ff7b16"
    />
    <path
      data-name="Path 787"
      d="M14.316 1.678a9.061 9.061 0 0 1 8.763 6.793 2.5 2.5 0 0 1 1.123-.265h.54a10.723 10.723 0 0 0-20.846 0h.54a2.5 2.5 0 0 1 1.123.265 9.061 9.061 0 0 1 8.761-6.793Z"
      fill="#ff7b16"
    />
    <path
      data-name="Path 788"
      d="M22.913 21.869a5.459 5.459 0 0 1-5 3.289h-1.1a2.636 2.636 0 1 0 0 1.678h1.1a7.14 7.14 0 0 0 6.669-4.612h-.38a2.5 2.5 0 0 1-1.286-.355Z"
      fill="#ff7b16"
    />
    <path
      data-name="Path 789"
      d="M27.794 9.883H24.2a.839.839 0 0 0-.839.839v8.985a.839.839 0 0 0 .839.839h3.594a.839.839 0 0 0 .839-.839v-8.985a.839.839 0 0 0-.839-.839Z"
      fill="#ff7b16"
    />
  </svg>
)

export default CallCenter
