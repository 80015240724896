import * as React from "react";
import { useState } from "react";
import { languages } from "./data";
import { Link } from "gatsby";
import QuotePopUp from "../../components/popup/quotePopUp/index";
import ContactusMobile from "components/reusableComponents/contactusMobile/ContactusMobile";
import arrowDownSvg from "assets/icons/iconDownWhite.svg";

const RelativeLanguages = ({ open, setOpen, head }) => {
  const [openContactusMobileTwo, setOpenContactusMobileTwo] = useState(false);

  return (
    <div className="bg-banner2-ads bg-no-repeat bg-cover">
      <div className="py-10 sm:py-20 relative 2xl:px-0 max-w-5xl mx-auto">
        <div className="flex flex-col lg:flex-row items-start gap-3 xl:gap-32">
          <div className="flex flex-col px-10 md:px-8">
            <h2 className="text-[35px] sm:text-[40px] font-bold font-secondary text-[#00173A] my-5 max-w-2xl">
              {head
                ? head
                : "Serving Over 120 Languages and 950+ Language Pairs"}
            </h2>
            <div className="md:flex flex-col lg:flex-row xl:gap-10 items-baseline">
              <div className="max-w-xl xl:max-w-2xl">
                <p className="text-[16px] sm:text-lg font-regular font-opensans pt-1 text-[#00173A]">
                  There’s no need to worry about language coverage: Tomedes is
                  one of the few companies to offer professional translation
                  services in over 120 languages. From the most popular
                  languages to the rare and often under-served languages, we can
                  easily support almost any urgent project thrown at us. Do not
                  hesitate to contact our team to see the full list of supported
                  languages today.
                </p>

                <button
                  onClick={() => setOpenContactusMobileTwo(true)}
                  className="h-[52px] w-full gap-x-4 sm:w-[208px] text-base text-center font-opensans font-semibold text-white bg-orange hover:bg-[#FF8F3A] rounded-full cursor-pointer inline-flex items-center justify-center mt-7 mb-5 self-start sm:hidden px-10 hover:bg-yellowHover transition-colors relative"
                >
                  CONTACT US
                  <img
                    src={arrowDownSvg}
                    alt="arrow down"
                    className={`${
                      openContactusMobileTwo ? "visible" : "invisible absolute"
                    } `}
                  />
                </button>
                <button
                  onClick={() => setOpen(true)}
                  className="h-[52px] hidden w-full sm:w-[208px] text-base text-center font-opensans font-semibold text-white bg-orange hover:bg-[#FF8F3A] rounded-full cursor-pointer sm:inline-flex items-center justify-center mt-7 mb-5 self-start px-10 hover:bg-yellowHover transition-colors "
                >
                  CONTACT US
                </button>
              </div>
              <div className="absolute left-0">
                <ContactusMobile
                  open={openContactusMobileTwo}
                  setOpen={setOpenContactusMobileTwo}
                  blueTheme
                  noPlaceholder
                />
              </div>
              <div
                className={`flex flex-col sm:px-28 md:px-12 2xl:max-w-sm ${
                  openContactusMobileTwo && "pt-[38rem]"
                }`}
              >
                <ul className="grid gap-y-5 pt-6 pb-12 grid-column-end grid-cols-2 gap-x-24">
                  {languages.map((item, i) => (
                    <li key={i} className="flex gap-3 items-center">
                      <img
                        src="https://tomedes.gumlet.io/assets/quality-policy/icon-check.svg"
                        alt="check"
                        className="w-6 h-5"
                      />
                      <p className="text-base font-opensans text-blue hover:text-yellowHover transition-colors">
                        {item.text}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuotePopUp open={open} setOpen={setOpen} />
    </div>
  );
};

export default RelativeLanguages;
