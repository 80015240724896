import React from "react";
import third from "assets/groupPeople.png";

const CarThree = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={third} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Best Client Experience
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            We at Tomedes put you, the client, at <br /> the forefront of
            everything we do from <br /> start to finish. Our 98% satisfaction
            rate <br /> is our guarantee.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarThree;
