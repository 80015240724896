import SecondAdComp from "components/homepage/Ads-Page/secondAdComp";
import HomepageReviews from "components/homepage/homepageReviews";
import ReviewCards from "components/homepage/reviewcard";
import ReviewCardsDesign2 from "components/homepage/reviewcard/design2";
import React from "react";
import { SecondNewData } from "../homepage/Ads-Page/config";
import Services from "./Services";
import { ServicesData, ServicesData2 } from "components/LP/data";
import GroupVector from "assets/groupVector.png";

const ReviewSection = ({
  open,
  setOpen,
  heading,
  data,
  data2,
  contentTop,
  cardsData,
  hideCarousel,
  showBottomCarousel,
}) => {
  return (
    <div className="bg-gradient-to-b from-white to-[#E8F1F5]">
      <Services
        background="bg-none"
        heading={heading}
        servicesdata={data ? data : ServicesData}
        servicesdata2={data2 ? data2 : ServicesData2}
        paragraphText={
          contentTop
            ? contentTop
            : "At Tomedes, we pride ourselves on the versatility of our translation services. Our translators have extensive experience in translating different kinds of documents and material."
        }
        secondPara="These include but are not limited to:"
        btn="CONTACT US"
        addImag={GroupVector}
        open={open}
        setOpen={setOpen}
      />
      <SecondAdComp
        data={cardsData ? cardsData : SecondNewData}
        midContent
        bgCol="bg-none"
        newCarousel
        title="What Are You Guaranteed At Tomedes?"
        fontBold
        headTitle="What Are You Guaranteed At Tomedes?"
        NewCards
        customWidthPara="w-[239px]"
        parentDivwidth
        gapOn2xl
        NotshowCarousel={hideCarousel}
        LegalBottomCarousel={showBottomCarousel}
      />
    </div>
  );
};

export default ReviewSection;
