import React from "react";
import Checked from "assets/CheckedIcon.svg";

const LegalTopCardTwo = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={Checked} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Confidentiality
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            We understand—dealing with legal <br /> texts is a highly sensitive{" "}
            <br /> matter. Our translation workflow <br /> is designed to
            maximize confidentiality <br /> at points of the process,
            guaranteeing full <br /> security. Your legal documents <br /> are
            safe with us.
          </p>
        </div>
      </div>
    </div>
  );
};
export default LegalTopCardTwo;
