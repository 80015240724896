import * as React from "react"

const BusinessMember = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28.102}
    height={23.63}
    {...props}
  >
    <g data-name="Group 3317" fill="#ff7b16">
      <path
        data-name="Path 2927"
        d="M0 13.187c.143-.437.188-.9.371-1.328a5.2 5.2 0 0 1 4.134-3.277 16.732 16.732 0 0 1 2.519-.061c.649-.01 1.3 0 1.948 0 .129 0 .189.025.2.177a5.022 5.022 0 0 0 1.456 3.25 1.125 1.125 0 0 1 .078.106 6.259 6.259 0 0 0-1.987 1.024 6.162 6.162 0 0 0-2.461 4.8c-.015.383-.023.769 0 1.151.015.26-.064.311-.314.29A25.85 25.85 0 0 1 .63 18.307l-.628-.188Z"
      />
      <path
        data-name="Path 2928"
        d="M21.278 19.82c0 .749-.007 1.5.005 2.248a.323.323 0 0 1-.222.347 12.258 12.258 0 0 1-3.349.974 22.107 22.107 0 0 1-9.955-.846c-.523-.157-.524-.156-.524-.7 0-1.307-.02-2.615.009-3.921a5.212 5.212 0 0 1 4.955-5.13 38.8 38.8 0 0 1 4.33.008 5.284 5.284 0 0 1 4.544 3.8 6.648 6.648 0 0 1 .206 1.652c.004.525.001 1.051.001 1.568Z"
      />
      <path
        data-name="Path 2929"
        d="M17.798 12.051a8.246 8.246 0 0 0 .683-.835 4.882 4.882 0 0 0 .839-2.455c.009-.175.051-.25.252-.247 1.143.013 2.286-.011 3.429.014a5.235 5.235 0 0 1 5.068 4.942c.061 1.479.015 2.962.026 4.443 0 .149-.085.18-.182.228a11.586 11.586 0 0 1-2.913.915 19.4 19.4 0 0 1-2.529.3c-.178.011-.213-.029-.213-.2a11.281 11.281 0 0 0-.083-2.052 6.175 6.175 0 0 0-4.13-4.963c-.071-.026-.146-.052-.247-.09Z"
      />
      <path
        data-name="Path 2930"
        d="M10.14 8.359a4.118 4.118 0 0 1 4.115-4.112 4.138 4.138 0 0 1 4.12 4.116 4.123 4.123 0 0 1-4.139 4.118 4.1 4.1 0 0 1-4.096-4.122Z"
      />
      <path
        data-name="Path 2931"
        d="M7.029.002a4.115 4.115 0 0 1 4.113 4.079.488.488 0 0 1-.184.421 4.937 4.937 0 0 0-1.7 2.889.391.391 0 0 1-.2.3 4.122 4.122 0 0 1-6.08-2.775A4.122 4.122 0 0 1 7.029.002Z"
      />
      <path
        data-name="Path 2932"
        d="M21.08 0a4.116 4.116 0 0 1 4.1 3.869 4.109 4.109 0 0 1-3.745 4.344 4.091 4.091 0 0 1-1.877-.273.359.359 0 0 1-.268-.319 4.983 4.983 0 0 0-2.186-3.456.34.34 0 0 1-.138-.372A4.12 4.12 0 0 1 21.08 0Z"
      />
    </g>
  </svg>
)

export default BusinessMember
