import * as React from "react"

const GuranteeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.529}
    height={29.832}
    {...props}
  >
    <path
      data-name="Path 780"
      d="M9.072 23.843a3.587 3.587 0 0 1-4.12-1.5 3.6 3.6 0 0 1-2.16-.836q-.116-.1-.222-.2L.621 25.385a.885.885 0 0 0 .994 1.245l3.906-.886 1.765 3.6a.886.886 0 0 0 .795.5.885.885 0 0 0 .794-.5l2.265-4.74a3.6 3.6 0 0 1-2.073-.745Zm0 0"
      fill="#ff7b16"
    />
    <path
      data-name="Path 781"
      d="M19.736 21.507a3.6 3.6 0 0 1-2.16.836 3.588 3.588 0 0 1-4.12 1.5 3.6 3.6 0 0 1-2.073.745l2.265 4.74a.885.885 0 0 0 .794.5.885.885 0 0 0 .795-.5l1.765-3.6 3.906.886a.885.885 0 0 0 .994-1.245l-1.949-4.078q-.101.119-.217.216Zm0 0"
      fill="#ff7b16"
    />
    <path
      data-name="Path 782"
      d="M22.142 10.866a1.819 1.819 0 0 0-.47-2.664.885.885 0 0 1-.374-1.024 1.819 1.819 0 0 0-1.353-2.343.885.885 0 0 1-.7-.834 1.819 1.819 0 0 0-2.072-1.739.885.885 0 0 1-.943-.545 1.819 1.819 0 0 0-2.541-.925.886.886 0 0 1-1.073-.191 1.819 1.819 0 0 0-2.7 0 .886.886 0 0 1-1.078.191 1.819 1.819 0 0 0-2.54.925.885.885 0 0 1-.943.545 1.819 1.819 0 0 0-2.074 1.739.885.885 0 0 1-.7.835 1.819 1.819 0 0 0-1.353 2.342.885.885 0 0 1-.373 1.023 1.819 1.819 0 0 0-.47 2.664.885.885 0 0 1 0 1.089 1.819 1.819 0 0 0 .47 2.664.885.885 0 0 1 .373 1.024 1.819 1.819 0 0 0 1.352 2.343.885.885 0 0 1 .7.835 1.819 1.819 0 0 0 2.072 1.739.885.885 0 0 1 .946.542 1.82 1.82 0 0 0 2.542.925.885.885 0 0 1 1.073.189 1.819 1.819 0 0 0 2.705 0 .885.885 0 0 1 1.073-.189 1.819 1.819 0 0 0 2.542-.925.885.885 0 0 1 .944-.545 1.819 1.819 0 0 0 2.072-1.739.885.885 0 0 1 .7-.835 1.819 1.819 0 0 0 1.349-2.339.885.885 0 0 1 .373-1.024 1.819 1.819 0 0 0 .47-2.664.885.885 0 0 1 0-1.09Zm-10.878 8.3a7.756 7.756 0 1 1 7.755-7.755 7.764 7.764 0 0 1-7.755 7.755Zm0 0"
      fill="#ff7b16"
    />
    <path
      data-name="Path 783"
      d="M11.264 5.424a5.985 5.985 0 1 0 5.985 5.985 5.992 5.992 0 0 0-5.985-5.985Zm-3.739 4.887a.885.885 0 0 1 1.252 0l1.764 1.764 3.21-3.21a.885.885 0 1 1 1.252 1.252l-3.835 3.836a.885.885 0 0 1-1.252 0l-2.39-2.39a.885.885 0 0 1 0-1.251Zm0 0"
      fill="#ff7b16"
    />
  </svg>
)

export default GuranteeIcon
