import React from "react";
// import LogoSvg from "assets/svg/logo";
import BBC from "assets/images/new-homepage-assets/desktop/bbc_logo.svg";

const Card = ({ borderCss }) => {
  return (
    <div
      className={`right bg-white pl-[26px] sm:pl-10 p-10 sm:w-[409px] relative sm:mt-0 ml-1 mt-8 ${
        borderCss ? borderCss : ""
      }`}
      style="box-shadow: 0px 6px 10px #00000029; border-radius: 22px;"
    >
      <p className="font-bold text-[18px] font-opensans">
        "Speedy return & <br /> reasonably priced"
      </p>
      <p className="text-[#00173A] font-opensans leading-relaxed tracking-wider pt-5">
        A translation company well equipped with knowledge of hundreds of
        different languages - speedy return & reasonably priced.
      </p>

      <div className="pt-24">
        <img src={BBC} alt="" />
        <p className="text-[18px] font-bold font-opensans pt-3">
          Katherine Lindsay
        </p>
      </div>
    </div>
  );
};

export default Card;
