import React from "react";
import first from "assets/tickGroup.svg";

const CarOne = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={first} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Proven High-Quality Results
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            We are tried and tested by major global <br /> heavyweights like
            Google and Amazon. <br /> Your translation project will be treated
            with <br /> just as much care to ensure the quality of <br /> work.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarOne;
