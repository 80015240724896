import React from "react";
import fourth from 'assets/ads-assets/fast.svg';


const CarFour = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={fourth} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
          Fast Delivery          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
          Do you have an urgent  project? No worries! <br /> Tomedes guarantees fast, dependable delivery for <br /> translations of any size or complexity. Just sit back, <br /> relax, and let us do the work.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarFour;
