import React from "react";
import GroupIcon from "assets/GroupIcon.svg";

const CarThreeNew = () => {
  return (
    <div className="bottoM w-full sm:w-10/12 flex flex-col justify-center mx-auto ">
      <div className="flex sm:flex-row flex-col gap-x-6 justify-between pt-[4rem]">
        <div className="text-center">
          <img src={GroupIcon} alt="" className="mx-auto pb-2" />
          <h3 className="text-[20px] font-semibold text-[#00173A] font-secondary">
            Your Own <br /> Dedicated Team
          </h3>
          <p className="text-[15px] font-opensans text-[#00173A] leading-loose pt-8">
            Your translation will be handled <br /> by a specially assembled
            team, <br /> with your very own account <br /> manager for all your
            project’s <br /> needs.
          </p>
        </div>
      </div>
    </div>
  );
};
export default CarThreeNew;
