import first from "assets/ads-assets/customer.svg";
import second from "assets/ads-assets/98.svg";
import third from "assets/ads-assets/ISO.svg";
import fourth from "assets/ads-assets/fast.svg";
import ShareIcon from "assets/tickGroup.svg";
import Headphones from "assets/wifiGroup.svg";
import Globe from "assets/groupPeople.png";
import CheckedIcon from "assets/CheckedIcon.svg";
import HeadphoneIcon from "assets/Headphones.svg";
import GroupPeople from "assets/GroupIcon.svg";

export const Data = [
  {
    picture: first,
    head: "95,000 ",
    head2: "Business Customers",
    content:
      "Tomedes is delighted to count over 95,000 businesses worldwide among our clients. Our years of dedication and commitment to every project has led us to work hand-in-hand with Fortune 500 companies as well as small-to-medium businesses all around the globe.",
  },
  {
    picture: second,
    head: "98% Customer",
    head2: "Satisfaction Rate",
    content:
      "Tomedes’ exceptional reputation is built on years of exceptional services and high-quality solutions for all types of corporate translation needs around the world.",
  },
  {
    picture: third,
    head: "ISO Certified ",
    head2: "Providing High Quality",
    content:
      "As an ISO-certified translation provider, you can be sure that your project is of the highest level of quality assurance and care. At Tomedes you have 3 possibilities: ISO 17100:2015 for translation quality, ISO 9001:2015 for quality management systems, and ISO 18587:2017 for machine translation post editing.",
  },
  {
    picture: fourth,
    head: "Fast ",
    head2: "Delivery",
    content:
      "Do you have an urgent project? No worries! Tomedes guarantees fast, dependable delivery for translations of any size or complexity. Just sit back, relax, and let us do the work.",
  },
];

export const NewData = [
  {
    picture: ShareIcon,
    head: "Proven High-Quality Results",
    // head2: "Business Customers",
    content:
      "We are tried and tested by major global heavyweights like Google and Amazon. Your translation project will be treated with the greatest amount of care to meet our established high standards of quality.",
  },
  {
    picture: Headphones,
    head: "Wide Range of Expertise",
    // head2: "Satisfaction Rate",
    content:
      "Our translators work with a diverse range of languages and industries. We guarantee we have a language professional that perfectly responds to your company’s needs.",
  },
  {
    picture: Globe,
    head: "Best Client Experience",
    // head2: "Providing High Quality",
    content:
      "At Tomedes, we put our clients at the forefront of everything we do from start to finish. Our 98% customer satisfaction rate is testament to our commitment to providing fast, accessible, and quality translation services.",
  },
];

export const LegalData = [
  {
    picture: ShareIcon,
    head: "Proven High-Quality Results",
    // head2: "Business Customers",
    content:
      "We are tried and tested by major global heavyweights like Google and Amazon. Your translation project will be treated with just as much care to ensure the quality of work.",
  },
  {
    picture: Headphones,
    head: "Confidentiality",
    // head2: "Satisfaction Rate",
    content:
      "We understand—dealing with legal texts is a highly sensitive matter. Our translation workflow is designed to maximize confidentiality at points of the process, guaranteeing full security. Your legal documents are safe with us.",
  },
  {
    picture: Globe,
    head: "Fast Turnaround",
    // head2: "Providing High Quality",
    content:
      "We provide round-the-clock service so we’re ready to start your translation project anytime. Our legal translators handle legal language with professional ease, helping you meet the highly demanding deadlines of the legal sector.",
  },
];

export const SecondNewData = [
  {
    picture: CheckedIcon,
    head: "One-Year",
    head2: "Guarantee",
    content:
      "All our translation projects come with the promise of swift support for any revision requirements for up to one year.",
  },
  {
    picture: HeadphoneIcon,
    head: "Online 24/7",
    head2: "Customer Support",
    content:
      "Our dedicated team works around the clock to fulfill any urgent translation service need or answer any question that you may have. At Tomedes, our customers are our top priority.",
  },
  {
    picture: GroupPeople,
    head: "Your Own ",
    head2: "Dedicated Team",
    content:
      "Your translation will be quickly handled by a specially assembled team, with your very own account manager who caters to your specific project needs.",
  },
];

export const LegalSecondNewData = [
  {
    picture: CheckedIcon,
    head: "One-Year",
    head2: "Guarantee",
    content:
      "All our translation projects come with the promise of support in case of quality issues for up to one year.",
  },
  {
    picture: HeadphoneIcon,
    head: "Online 24/7",
    head2: "Customer Support",
    content:
      "Our dedicated team works around the clock to ensure that we can answer your message within minutes.",
  },
  {
    picture: GroupPeople,
    head: "Your Own ",
    head2: "Dedicated Project Manager",
    content:
      "Your project will be helmed by a dedicated project manager who will be your single point of contact throughout your translation journey.",
  },
];
