import * as React from "react";

const IconStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23.116}
    height={23.116}
    {...props}
  >
    <path
      data-name="Icon material-stars"
      d="M11.546 0a11.558 11.558 0 1 0 11.57 11.558A11.552 11.552 0 0 0 11.546 0Zm4.9 18.493-4.889-2.947-4.888 2.947 1.294-5.559L3.652 9.2l5.687-.485 2.219-5.247L13.777 8.7l5.687.485-4.311 3.733 1.294 5.571Z"
      fill="#ff7b16"
    />
  </svg>
);

export default IconStar;
