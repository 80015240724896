import React from "react";

const ThirdComp = ({
  firstHead,
  secondHead,
  thirdHead,
  contentOne,
  contentTwo,
}) => {
  return (
    <div className="bg-[#F6FCFF] py-12">
      <div className="w-10/12 2xl:w-8/12 3xl:w-7/12 mx-auto flex justify-between flex-wrap">
        <h2 className="text-[27px] sm:text-[40px] text-[#00173A] font-secondary font-bold md:w-[50%]">
          {firstHead ? firstHead : "What’s Our"}
          <br className="hidden sm:block" />
          <span className="text-orange">
            {secondHead ? secondHead : "Translation"}
            <span className="text-[#00173A]">
              {" "}
              {thirdHead ? thirdHead : "Process?"}
            </span>
          </span>
        </h2>
        <div className="text-[18px] pt-12 sm:pt-0 font-opensans leading-relaxed text-[#00173A] sm:w-[494px]">
          <p>
            {contentOne
              ? contentOne
              : "We are committed to always delivering your translation project on time. Our quick translation services adapt to your needs and our account managers work with you every step of the way to ensure complete transparency and understanding throughout the process."}
          </p>
          <p className="pt-6">
            {contentTwo
              ? contentTwo
              : "Our team of dedicated translators take the utmost care to deliver the best possible results before handing the output over to the keen eyes of a senior editor for quality assurance. And if that’s not enough, all our translations come with our 1-year guarantee so we can make any revisions if ever need be."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThirdComp;
