import React from "react";
import Web from "../../../assets/web.svg";

const About = ({ content }) => {
  return (
    <div className="mx-auto bg-[#F6FCFF]">
      <div className="grid lg:grid-cols-2 justify-center items-center gap-16 py-24 px-10">
        <div className="m-auto sm:block hidden">
          <img src={Web} alt="" className="md:w-[437px] md:h-[397px]" />
        </div>
        <div className="max-w-4xl">
          <h2 className="font-secondary text-[#00173A] text-[35px] sm:text-40 font-semibold sm:font-bold mb-8">
            <span className="text-orange">About</span> Tomedes
          </h2>
          <p className="font-opensans text-[#00173A] text-lg mb-10 max-w-[41rem]">
            {content
              ? content
              : "Ofer Tirosh founded Tomedes in 2007 with the goal of providing translation services for businesses looking to expand beyond their borders. The company has since grown into a leading multinational operation with offices in the US and the UK."}
          </p>
          <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 max-w-2xl">
            <div>
              <p className="font-opensans text-lg font-bold text-[#00173A] mb-4">
                UNITED STATES
              </p>
              <span className="font-opensans text-14 text-[#00173A]">
                Tomedes Ltd - USA 9450 <br />
                SW Gemini Dr #34540, <br />
                Beaverton, OR 97008-7105 <br />
                Call : +1 985 239 0142
              </span>
            </div>
            <div>
              <p className="font-opensans text-lg text-[#00173A] font-bold mb-4">
                EUROPE
              </p>
              <spna className="font-opensans text-14 text-[#00173A]">
                7 Bell Yard, <br />
                London, WC2A 2 <br />
                JR United Kingdom,
                <br />
                Call: +44 (0)16 1509 6140
              </spna>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
